* {
  font-size: 12px;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}

#app {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
}

.navbar {
  background-color: #252829;
  border-radius: 0px;
}

.nav-pills .nav-link.active {
  background-color: #3c6bf6;
  color: white;
}

a {
  color: #3c6bf6;
}

.btn-primary {
  background-color: #0e537d;
  border: #0e537d;
}

.btn-primary:hover {
  background-color: #0e537d;
  border: #0e537d;
}

.btn-primary:focus {
  background-color: #0e537d !important;
  border: #0e537d !important;
}

.btn-primary:active {
  background-color: #0e537d !important;
  border: #0e537d !important;
}

.alert-success {
  color: #155724;
  background-color: #b6fccb;
  border-color: #c3e6cb;
}
.alert-error {
  color: #155724;
  background-color: #d22948;
  border-color: #731015;
}
.alert-primary {
  color: blue;
  background-color: blue;
  border-color: #731015;
}
.parent {
  margin: 40px;
}
.accordion .fa {
  margin-right: 0.5rem;
}
/* #headingOne {
  margin-left: -10px;
}
#headingTwo {
  margin-left: -10px;
}
#headingThree {
  margin-left: -10px;
} */

.is-invalid {
  border: 1px solid red;
}
.well {
  min-height: 800px;
}

.well text-left {
  min-width: 300px;
}
p {
  display: inline-block;
}

.buttontbn {
  left: 100px;
}

.popup .popuptext {
  position: absolute;
  left: 10px;
  top: 10px;
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.submitbtn {
  padding: 5px 15px;
  background: #0e537d;
  color: white;
  border: 0 none;
  cursor: pointer;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  width: 100%;
}

.submitbtn:hover {
  background: #ffba2d;
}

.submitbtn:active {
  background: #b8861f;
}

.submitbtn:focus {
  outline: none;
}

.dropdown {
  text-align: center;
}
#widthy {
  min-width: 700px;
}

#fade {
  display: none;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1001;
  -moz-opacity: 0.8;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

#light {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 600px;
  max-height: 360px;
  margin-left: -300px;
  margin-top: -180px;
  border: 2px solid #fff;
  background: #fff;
  z-index: 1002;
  overflow: visible;
}

#boxclose {
  float: right;
  cursor: pointer;
  color: #fff;
  border: 1px solid #aeaeae;
  border-radius: 3px;
  background: #222222;
  font-size: 31px;
  font-weight: bold;
  display: inline-block;
  line-height: 0px;
  padding: 11px 3px;
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 1002;
  opacity: 0.9;
}

.boxclose:before {
  content: 'CLOSE';
}

#fade:hover ~ #boxclose {
  display: none;
}

.test:hover ~ .test2 {
  display: none;
}
#accessdiv {
  display: none;
}

.collapsible {
  background-color: #777;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

/* .active,
.collapsible:hover {
  background-color: #555;
} */

.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: #f1f1f1;
}
.form-control {
  /* width: 500px; */
}

.fieldsinput {
  display: block;
  width: 70%;
}

.firstdetails1 {
  display: inline;
  float: left;
  width: 50%;
}

.firstdetails2 {
  display: inline;
  width: 50%;
  height: 100%;
}
#inputform {
  height: 100%;
}
.leftradio {
  padding-right: 10px;
}
.messagebox {
  display: none;
}

#files {
  display: inline;
}
#awsKey,
#awsRegion {
  width: 200px;
}
#s3Bucket {
  width: 250px;
}
#signerUrl {
  width: 350px;
}
label,
input[type='radio'],
input[type='checkbox'] {
  cursor: pointer;
}
.errors {
  color: #ff0000;
}
.progress-clock {
  min-width: 140px;
  min-height: 60px;
  width: 60px;
  float: left;
  text-align: center;

  border: black;
  border-width: 2px;
  border-style: dotted;
  padding: 3px;
  margin: 3px;
}

.progress-clock svg path {
  transition: stroke 1.5s ease;
}

.progress-clock p.progressbar-text {
  transition: color 1.5s ease;
}

.progress-clock.evaporating svg path,
.progress-clock.evaporating p.progressbar-text {
  stroke: #4a55cb;
  color: #4a55cb;
}

.progress-clock.canceled svg path,
.progress-clock.canceled p.progressbar-text {
  stroke: #d1ff0a;
  color: #d1ff0a;
}

.progress-clock.completed svg path,
.progress-clock.completed p.progressbar-text {
  stroke: #009a00;
  color: #009a00;
}

.progress-clock.error svg path,
.progress-clock.error p.progressbar-text {
  stroke: #c71704;
  color: #c71704;
}

.progress-clock.warning svg path,
.progress-clock.warning p.progressbar-text {
  stroke: #f59f08;
  color: #f59f08;
}

.progress-clock.pausing svg path,
.progress-clock.pausing p.progressbar-text {
  stroke: #9406f5;
  color: #9406f5;
}

.progress-clock.paused svg path,
.progress-clock.paused p.progressbar-text {
  stroke: #9d7521;
  color: #9d7521;
}

.card-header {
  border: 1px solid whitesmoke;
}

/* Button used to open the contact form - fixed at the bottom of the page */
.open-button {
  background-color: #555;
  color: white;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  width: 100%;
  display: block;
  padding: 14px 28px;
  text-align: center;
}

/* The popup form - hidden by default */
.form-popup {
  display: none;
  right: auto;
  /* margin: 0 auto; */
  left: auto;
  right: 15px;
  z-index: 9;
}

/* Add styles to the form container */
.form-container {
  max-width: 400px;
  margin-top: 50px;
}

#jobs {
  /* min-width: 250px; */
}

.form-control {
  /* width: 250px; */
}

button.btn.btn-link {
  color: white !important;
}

.nav-link:hover {
  background: #ffba2d;
}

#editButtons .nav-link.active {
  background: #0e537d;
  color: white;
}

#editButtons .nav-link.active:hover {
  background: #ffba2d;
}

#editButtons .nav-link {
  color: #fff;
}

#leftul,
#rightul {
  color: black !important;
}

input {
  /* background-color: #0e537d; */
}

.form-control {
  background: #dcdcdc;
  color: #ddd;
  border-color:#dcdcdc;
}

.btn-primary {
  background-color: #0e537d;
  color: #ddd;
}

.form-control:focus {
  background: #dcdcdc;
  color: #ddd;
  border-color: #dcdcdc;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #000;
  opacity: 1;
  color: #ddd;
}

#loading {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5) center center no-repeat;
}

.spinner {
  position: absolute;
  left: calc(50% - 30px);
  top: 50%;
  height: 60px;
  width: 60px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgba(0, 174, 239, 0.15);
  border-right: 6px solid rgba(0, 174, 239, 0.15);
  border-bottom: 6px solid rgba(0, 174, 239, 0.15);
  border-top: 6px solid rgba(0, 174, 239, 0.8);
  border-radius: 100%;
}

.success {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  background-color: green;
  border-radius: 35px;
}

.success::before {
  content: '✓';
  color: white;
  font-size: 50px;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.badge {
  color: red;
}

li.list-group-item.node-treeview {
  padding: 5px 5px !important;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#treeview {
  color: white;
}

.removeSelectedAsset {
  cursor: pointer;
}

.resume,
.cancel,
.pause {
  padding: 1px 6px 2px 6px;
  margin: 10px 5px;
}

label {
  font-size: 11px;
}

.card-header {
  padding: 5px 10px;
}

.main-comment-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  background-color: #576068;
  margin-top: 10px;
  border-radius: 0.25rem;
}

.comment-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-radius: 0.25rem;
}

.comment-status-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.comment-author {
  font-weight: bold;
}

.comment-status-btn {
  width: 15px;
  height: 15px;
  border: 1px solid #3e3c3c;
  border-radius: 15px;
  cursor: pointer;
}

.comment-replies-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-left: 30px;
  flex-direction: column;
}

.comment-reply-container {
  background-color: #576068;
  width: 95%;
  padding: 10px;
  display: block;
  width: 100%;
  margin-top: 10px;
  border-radius: 0.25rem;
}

.textarea-comment {
  width: 100%;
  padding: 8px;
  background-color: rgb(15, 16, 20);
  border: 0px;
  border-radius: 0.25rem;
  color: white;
}

.textarea-comment::placeholder {
  color: rgba(125, 130, 156, 0.6);
}

.textarea-comment:focus {
  outline: none;
}

.little-reply-btn {
  margin-top: 4px;
  color: rgb(125, 130, 156);
  cursor: pointer;
  user-select: none;
}

.little-reply-btn:hover {
  color: rgb(184, 193, 207);
}

.little-reply-btn:active {
  color: rgb(125, 130, 156);
}

a.nav-link.active {
  background: #ffba2d;
}

a.nav-link {
  color: white;
}

.node-selected {
  color: white !important;
}
